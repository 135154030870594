import {
  Flex,
  Box,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { CircularProgress } from "@mui/material";
export default function ServiceTable(props) {
  const { columnsData, tableData, onClickCategory, onClickBuy, onClickPrice, onClickDetail, onClickDownload, loading, friend } = props;
  const [activeMenu, setActiveMenu] = useState('profiling');
  const [serviceData, setServiceData] = useState([]);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  useEffect(() => {
    if (data) {
      setServiceData(data.profiling)
    }
  }, [data])

  const onChangeMenu = menu => {
    if (menu === 'profiling') {
      setActiveMenu('profiling');
      setServiceData(data.profiling);
    } else if (menu === 'matching') {
      setActiveMenu('matching');
      setServiceData(data.matching);
    } else if (menu === 'persona') {
      setActiveMenu('persona');
      setServiceData(data.persona);
    }
  }

  const handleDownload = ({ category, name, index }) => {
    onClickDownload({ category, name, index })
  }


  // const tableInstance = useTable(
  //   {
  //     columns,
  //     data,
  //   },
  //   useGlobalFilter,
  //   useSortBy,
  //   usePagination
  // );

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   page,
  //   prepareRow,
  //   initialState,
  // } = tableInstance;
  // initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction='column'
      w={{ md: '100%', lg: '80%' }}
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex gap={{ sm: 1, md: 30 }} flex={1} mb={5}>
        <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('profiling')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeMenu === 'profiling' ? '#059212' : '#fff'}>
          <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeMenu === 'profiling' ? '#FFF' : '#000'} fontWeight={activeMenu === 'profiling' ? 'bold' : 'normal'}>Profiling Service</Text>
        </Flex>
        {!friend && (
          <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('matching')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeMenu === 'matching' ? '#059212' : '#fff'}>
            <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeMenu === 'matching' ? '#FFF' : '#000'} fontWeight={activeMenu === 'matching' ? 'bold' : 'normal'}>Matching Service</Text>
          </Flex>
        )}
        <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('persona')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeMenu === 'persona' ? '#059212' : '#fff'}>
          <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeMenu === 'persona' ? '#FFF' : '#000'} fontWeight={activeMenu === 'persona' ? 'bold' : 'normal'}>Persona Service</Text>
        </Flex>
      </Flex>
      <Flex width="100%" gap={{ sm: 1, md: 5 }}>
        <Flex flex={3} width="100%" borderTopRadius={8} padding="5px 10px" backgroundColor={'#CADFB8'}>
          <Text color={'#000'} fontSize={{ sm: 'xs', md: 'sm' }} fontWeight='bold'>Type {activeMenu}</Text>
        </Flex>
        <Flex flex={1} width="100%" borderTopRadius={8} padding="5px 10px" backgroundColor={'#CADFB8'}>
          <Text color={'#000'} fontSize={{ sm: 'xs', md: 'sm' }} fontWeight='bold'>Pricing</Text>
        </Flex>
        <Flex flex={1} width="100%" borderTopRadius={8} padding="5px 10px" backgroundColor={'#CADFB8'}>
          <Text color={'#000'} fontSize={{ sm: 'xs', md: 'sm' }} fontWeight='bold'>Action</Text>
        </Flex>
        <Flex flex={3} width="100%" borderTopRadius={8} padding="5px 10px" backgroundColor={'#CADFB8'}>
          <Text color={'#000'} fontSize={{ sm: 'xs', md: 'sm' }} fontWeight='bold'>Description</Text>
        </Flex>
      </Flex>
      <Flex width="100%" direction="column" gap={2} mt={2}>
        {serviceData.map((item, index) => {
          const isPurchase = serviceData.find(element => (element.name === item.name && element.isPurchase === true));
          return (
          <Flex direction="row" width="100%" gap={{ sm: 1, md: 5 }} key={index} alignItems="center">
            <Flex flex={3} width="100%" minWidth={{ sm: '120px', md: '120px' }}>
              <Text fontSize={{ sm: 'xs', md: 'sm' }} color={'#000'}>{index + 1}. {item.name} {item.duration && `${item.duration} ${item.duration_type}`}</Text>
            </Flex>
            <Flex flex={1} width="100%">
              {item.isPurchase ? (
                <Text flex={1} fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '65px', md: '100px' }}>Sudah beli</Text>
              ) : (
                <Text cursor="pointer" textDecoration="underline" flex={1} fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '65px', md: '100px' }} onClick={() => onClickPrice({ category: item.category, service: activeMenu })}>Rp {new Intl.NumberFormat('id-ID').format(item.price)}</Text>
              )}
            </Flex>
            <Flex flex={1} width="100%">
              {item.isPurchase || isPurchase ? (
                <Flex flexDirection="row" width="100%" gap={1}>
                  <Flex flex={1}>
                    {item.type === "profiling" ? (
                      <Button
                        width="100%"
                        bgColor="green"
                        color='white'
                        fontSize={{ sm: 'xs', md: 'sm' }}
                        fontWeight='500'
                        borderRadius='5px'
                        onClick={() => handleDownload({ category: item.category, service: activeMenu, name: item.features, index: index })}
                        px='5px'
                        py='5px'
                        title="Download PDF">
                        {loading[index] ? (
                          <CircularProgress
                            thickness={4}
                            size={15}
                            sx={{
                              color: (theme) =>
                                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                            }}
                          />
                        ) : (
                          <DownloadIcon fontSize="sm" />
                        )}
                      </Button>
                    ) : (
                      <Button
                        width="100%"
                        bgColor="green"
                        color='white'
                        fontSize={{ sm: 'xs', md: 'sm' }}
                        fontWeight='500'
                        borderRadius='5px'
                        onClick={() => onClickCategory({ category: item.category, service: item.type, name: item.features })}
                        px='5px'
                        py='10px'
                        title="Open Web View">
                        <OpenInNewIcon fontSize="sm" />
                      </Button>
                    )}
                  </Flex>
                </Flex>
              ) : (
                <Flex flex={1}>
                  <Button
                    width="100%"
                    bgColor="green"
                    color='white'
                    fontSize={{ sm: 'xs', md: 'sm' }}
                    fontWeight='500'
                    borderRadius='5px'
                    onClick={() => onClickBuy({ category: item.category, service: activeMenu, name: item.name })}
                    px='15px'
                    py='5px'>
                    Beli
                  </Button>
                </Flex>
              )}
            </Flex>
            <Flex flex={3} width="100%">
              <Text color={'#000'}>{item.desc}</Text>
            </Flex>
          </Flex>
        )})}
      </Flex>
      {/* <Box>
        <Flex direction="row" gap={{ sm: 3, md: 1 }} flex={1} borderBottomWidth={1}>
          <Flex direction="row" flex={0.3} maxWidth={{ sm: '70px', lg: '100%' }}>
            <Text flex={0.3} fontWeight="bold" fontSize={{ sm: 'sm', md: 'md', lg: "lg" }}></Text>
          </Flex>
          <Flex direction="row" flex={8} gap={{ sm: 3, md: 0 }}>
            <Text flex={2} fontWeight="bold" minWidth={{ sm: '140px', md: '200px' }} fontSize={{ sm: 'sm', md: 'md', lg: "lg" }}>Service - Hasil Analytics</Text>
            <Text flex={1} fontWeight="bold" minWidth={{ sm: '80px', md: '100px' }} fontSize={{ sm: 'sm', md: 'md', lg: "lg" }}>Pricing</Text>
            <Text flex={1} fontWeight="bold" minWidth={{ sm: '100px' }} fontSize={{ sm: 'sm', md: 'md', lg: "lg" }}>Action</Text>
            <Text flex={3} fontWeight="bold" minWidth={{ sm: '200px' }} fontSize={{ sm: 'sm', md: 'md', lg: "lg" }}>Deskripsi</Text>
          </Flex>
        </Flex>
      </Box>
      <Box flexDirection="column" display="flex" gap={2} mt={3} justifyContent="center">
        <Flex flexDirection="column">
          <Flex direction="row" flex={1} gap={5} pb={2}>
            <Flex direction="column" flex={8} gap={3}>
              {data?.profiling.map((feat, index) => (
                <Flex flex={1} direction="row" gap={{ sm: 2.5, md: 0 }}>
                  <Text flex={0.3} fontSize={{ sm: 'xs', md: 'sm' }}>{index + 1}</Text>
                  <Text flex={2} fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '140px', md: '200px' }}>{feat.features === "Complete Profiling Book" ? `Buku Profil ${user?.name?.split(' ')[0]}  versi Complete` : `Buku Profil ${user?.name?.split(' ')[0]} versi Standard`}</Text>
                  {feat.isPurchase ? (
                    <Text flex={1} fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '80px', md: '100px' }}>Sudah beli</Text>
                  ) : (
                    <Text cursor="pointer" textDecoration="underline" flex={1} fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '80px', md: '100px' }} onClick={() => onClickPrice({ category: feat.category, service: 'Profiling Book' })}>Rp {new Intl.NumberFormat('id-ID').format(feat.price)}</Text>
                  )}
                  <Flex flex={1} minWidth={{ sm: '100px' }}>
                    {feat.isPurchase ? (
                      <Flex flexDirection="row" gap={2}>
                        <Button
                          bgColor="green"
                          color='white'
                          fontSize={{ sm: 'xs', md: 'sm' }}
                          fontWeight='500'
                          borderRadius='5px'
                          onClick={() => onClickDownload({ category: feat.category, service: 'Profiling Book', name: feat.features })}
                          px='12px'
                          py='5px'
                          title="Download PDF">
                          {loading ? (
                            <CircularProgress
                              thickness={4}
                              size={15}
                              sx={{
                                color: (theme) =>
                                  theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                              }}
                            />
                          ) : (
                            <DownloadIcon fontSize="sm" />
                          )}
                        </Button>
                        <Button
                          bgColor="green"
                          color='white'
                          fontSize={{ sm: 'xs', md: 'sm' }}
                          fontWeight='500'
                          borderRadius='5px'
                          onClick={() => onClickDetail({ category: feat.category, service: 'Profiling Book', name: feat.features })}
                          px='12px'
                          py='5px'
                          title="Open Web View">
                          <OpenInNewIcon fontSize="sm" />
                        </Button>
                      </Flex>
                    ) : (
                      <Button
                        bgColor="green"
                        color='white'
                        fontSize={{ sm: 'xs', md: 'sm' }}
                        fontWeight='500'
                        borderRadius='5px'
                        onClick={() => onClickBuy({ category: feat.category, service: 'Profiling Book', name: feat.name })}
                        px='30px'
                        py='5px'>
                        Beli
                      </Button>
                    )}
                  </Flex>
                  {feat.isPurchase ? (
                    <Text flex={3} cursor="pointer" fontSize={{ sm: 'xs', md: 'sm' }} textDecoration="underline" minWidth={{ sm: '180px' }} onClick={() => onClickDownload({ category: feat.category, service: 'Profiling Book', name: feat.features })}>{"Hasil Analytic sudah bisa Anda Download"}</Text>
                  ) : (
                    <Text flex={3} fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '180px' }}>{`${feat.duration ?? "Sekali beli"}, Hasil ${feat.features === "Complete Profiling Book" ? 25 : 15} Bab Analytic dapat didownload`}</Text>
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Divider orientation="horizontal" />
        </Flex>
        <Flex flexDirection="column">
          <Flex direction="row" flex={1} gap={1} pb={2}>
            <Flex direction="column" flex={8} gap={3}>
              {data?.profiling_web.map((feat) => (
                <Flex flex={1} direction="row" gap={{ sm: 2.5, md: 0 }}>
                  <Text fontSize={{ sm: 'xs', md: 'sm' }} flex={0.3}>3</Text>
                  <Text fontSize={{ sm: 'xs', md: 'sm' }} flex={2} minWidth={{ sm: '140px', md: '200px' }}>Profile {user?.name?.split(' ')[0]} versi webview</Text>
                  <Text cursor="pointer" textDecoration="underline" fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '80px', md: '100px' }} flex={1} onClick={() => onClickPrice({ category: feat.category, service: 'Profiling WEB' })}>Lihat pricing</Text>
                  <Flex fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '100px' }} flex={1}>
                    {feat.isPurchase ? (
                      <Button
                        bgColor="green"
                        color='white'
                        fontSize={{ sm: 'xs', md: 'sm' }}
                        fontWeight='500'
                        borderRadius='5px'
                        onClick={() => onClickCategory({ category: feat.category, service: 'Profiling WEB', name: feat.features })}
                        px='27px'
                        py='5px'>
                        View
                      </Button>
                    ) : (
                      <Button
                        bgColor="green"
                        color='white'
                        fontSize={{ sm: 'xs', md: 'sm' }}
                        fontWeight='500'
                        borderRadius='5px'
                        onClick={() => onClickBuy({ category: feat.category, service: 'Profiling WEB', name: feat.features })}
                        px='30px'
                        py='5px'>
                        Beli
                      </Button>
                    )}
                  </Flex>
                  {feat.isPurchase ? (
                    <Text fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '180px' }} flex={3}>Hasil Profiling dilihat di website</Text>
                  ) : (
                    <Flex flexDirection={{ sm: 'column', md: "row" }} flex={3} gap={1} minWidth={{ sm: '180px' }}>
                      <Text fontSize={{ sm: 'xs', md: 'sm' }}>{`${feat.duration ?? "Sekali beli"}, `}</Text>
                      <Text textDecoration="underline" cursor="pointer" fontSize={{ sm: 'xs', md: 'sm' }} onClick={() => onClickCategory({ category: feat.category, service: 'Profiling WEB', name: feat.name })}>Dilihat di website</Text>
                    </Flex>
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Divider orientation="horizontal" />
        </Flex>
        <Flex flexDirection="column">
          <Flex direction="row" flex={1} gap={5} pb={2}>
            <Flex direction="column" flex={8} gap={3}>
              {data?.persona.slice(0, 2).map((feat, index) => (
                <Flex flex={1} direction="row" gap={{ sm: 2.5, md: 0 }}>
                  <Text fontSize={{ sm: 'xs', md: 'sm' }} flex={0.3}>{index === 0 ? 4 : 5}</Text>
                  <Text fontSize={{ sm: 'xs', md: 'sm' }} flex={2} minWidth={{ sm: '140px', md: '200px' }}>{feat.name === "General Persona" ? `Persona ${user?.name?.split(' ')[0]} versi webview` : `Daily vibe ${user?.name?.split(' ')[0]} versi webview`}</Text>
                  <Text cursor="pointer" textDecoration="underline" fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '80px', md: '100px' }} flex={1} onClick={() => onClickPrice({ category: feat.category, service: 'Persona' })}>Lihat pricing</Text>
                  <Flex fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '100px' }} flex={1}>
                    {feat.isPurchase ? (
                      <Button
                        bgColor="green"
                        color='white'
                        fontSize={{ sm: 'xs', md: 'sm' }}
                        fontWeight='500'
                        borderRadius='5px'
                        onClick={() => onClickCategory({ category: feat.category, service: 'Persona', name: feat.name })}
                        px='27px'
                        py='5px'>
                        View
                      </Button>
                    ) : (
                      <Button
                        bgColor="green"
                        color='white'
                        fontSize={{ sm: 'xs', md: 'sm' }}
                        fontWeight='500'
                        borderRadius='5px'
                        onClick={() => onClickBuy({ category: feat.category, service: 'Persona', name: feat.name })}
                        px='30px'
                        py='5px'>
                        Beli
                      </Button>
                    )}
                  </Flex>
                  {feat.isPurchase ? (
                    <Text fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '180px' }} flex={3}>{feat.name === "General Persona" ? "Hasil Persona General" : "Hasil Persona Daily dilihat di website"}</Text>
                  ) : (
                    <Flex flexDirection={{ sm: 'column', md: "row" }} flex={3} gap={1} minWidth={{ sm: '180px' }}>
                      <Text fontSize={{ sm: 'xs', md: 'sm' }}>{feat.name === "General Persona" ? "Persona General, " : "Persona Daily, "}</Text>
                      <Text textDecoration="underline" cursor="pointer" fontSize={{ sm: 'xs', md: 'sm' }} onClick={() => onClickCategory({ category: feat.category, service: 'Persona', name: feat.name })}>Dilihat di website</Text>
                    </Flex>
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Divider orientation="horizontal" />
        </Flex>
        <Flex flexDirection="column">
          <Flex direction="row" flex={1} gap={5} pb={2}>
            <Flex direction="column" flex={8} gap={3}>
              {data?.matching.map((feat) => (
                <Flex flex={1} direction="row" gap={{ sm: 2.5, md: 0 }}>
                  <Text fontSize={{ sm: 'xs', md: 'sm' }} flex={0.3}>6</Text>
                  <Text fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '140px', md: '200px' }} flex={2}>Matching {user?.name?.split(' ')[0]} dan Pasangan : Partnership, Romance, Friendship</Text>
                  <Text cursor="pointer" textDecoration="underline" fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '80px', md: '100px' }} flex={1} onClick={() => onClickPrice({ category: feat.category, service: 'Matching' })}>Lihat pricing</Text>
                  <Flex fontSize={{ sm: 'xs', md: 'sm' }} minWidth={{ sm: '100px' }} flex={1}>
                    {feat.isPurchase ? (
                      <Button
                        bgColor="green"
                        color='white'
                        fontSize='sm'
                        fontWeight='500'
                        borderRadius='5px'
                        onClick={() => onClickCategory({ category: feat.category, service: 'Matching', name: feat.features })}
                        px='27px'
                        py='5px'>
                        View
                      </Button>
                    ) : (
                      <Button
                        bgColor="green"
                        color='white'
                        fontSize='sm'
                        fontWeight='500'
                        borderRadius='5px'
                        onClick={() => onClickBuy({ category: feat.category, service: 'Matching', name: feat.features })}
                        px='30px'
                        py='5px'>
                        Beli
                      </Button>
                    )}
                  </Flex>
                  {feat.isPurchase ? (
                    <Text flex={3} cursor="pointer" fontSize={{ sm: 'xs', md: 'sm' }} textDecoration="underline" minWidth={{ sm: '180px' }} onClick={() => onClickCategory({ category: feat.category, service: 'Matching', name: feat.features })}>Hasil Persona General dan Daily dilihat di website</Text>
                  ) : (
                    <Flex flexDirection={{ sm: 'column', md: "row" }} flex={3} gap={1} minWidth={{ sm: '180px' }}>
                      <Text fontSize={{ sm: 'xs', md: 'sm' }}>Matching General dan Daily, </Text>
                      <Text textDecoration="underline" cursor="pointer" fontSize={{ sm: 'xs', md: 'sm' }} onClick={() => onClickCategory({ category: feat.category, service: 'Matching', name: feat.name })}>Dilihat di website</Text>
                    </Flex>
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Divider orientation="horizontal" />
        </Flex>
      </Box> */}
    </Card>
  );
}
