import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { CircularProgress } from "@mui/material";

function TablePurchased(props) {
  const { columnsData, tableData, pagination, nextPage, prevPage, firstPage, lastPage, goToPage, limitPage, type, onClickAction, loading } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const CheckButton = row => {
    // console.log('row button', row)
    const [checkButton, setcheckButton] = useState({ value: "CheckOut" });
    const handleClick = () => {
      setcheckButton(prev => ({
        value: prev.value === "CheckOut" ? "CheckIn" : "CheckOut"
      }));
    };
    return (
      <Button
        key={row.id}
        id={row.id}
        onClick={handleClick}
        value={checkButton.value}
      >
        {checkButton.value}
      </Button>
    );
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  return (
    <>
      <Flex
        direction='column'
        w={{ md: '100%', lg: '80%' }}
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Table {...getTableProps()} variant='simple' color='gray.500'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor='transparent'>
                    <Flex
                      fontSize={{ sm: "12px", lg: "14px" }}
                      color='gray.400'
                      alignItems="center"
                      justifyContent="center"
                      borderTopRadius={5}
                      paddingY={1}
                      bgColor="#CADFB8">
                      <Text textAlign="center" fontWeight="bold" color="#000" fontSize={{ sm: "xs", md: "sm" }}>{column.render("Header")}</Text>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "DISPLAY NAME") {
                      if (row.original.is_has_user_id) {
                        data = (
                          <Text
                            color={"black"}
                            fontSize={{ sm: 'sm', md: 'md' }}
                            fontWeight='500'
                            overflow="hidden"
                            whiteSpace="nowrap"
                            maxWidth={150}
                            textOverflow="ellipsis">
                            {cell.value}
                          </Text>
                        )
                      } else {
                        data = (
                          <Text
                            color={"black"}
                            fontSize={{ sm: 'sm', md: 'md' }}
                            fontWeight='500'
                            overflow="hidden"
                            whiteSpace="nowrap"
                            maxWidth={150}
                            textOverflow="ellipsis">
                            {row.original.colleague.name}
                          </Text>
                        )
                      }
                    } else if (cell.column.Header === "ACTION" && cell.value.category === "File PDF") {
                      if (row.values.status === 1) {
                        data = (
                          <Flex flexDirection="row" gap={2}>
                            <Button
                              bgColor="green"
                              width="80%"
                              color='white'
                              fontSize={{ sm: 'xs', md: 'sm' }}
                              fontWeight='500'
                              borderRadius='5px'
                              onClick={() => onClickAction({ type: 'Download', product: row.original, index: index })}
                              px='20px'
                              py='9px'
                              title="Download PDF">
                              {loading[index] ? (
                                <CircularProgress
                                  thickness={4}
                                  size={15}
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                                  }}
                                />
                              ) : (
                                <DownloadIcon fontSize="sm" />
                              )}
                            </Button>
                            {/* <Button
                                bgColor="green"
                                color='white'
                                fontSize={{sm: 'xs', md: 'sm'}}
                                fontWeight='500'
                                borderRadius='5px'
                                onClick={() => onClickAction({type: 'Web', product: row.original})}
                                px='11px'
                                py='9px'
                                title="Open Web View">
                                <OpenInNewIcon fontSize="sm"/>
                              </Button> */}
                          </Flex>
                        )
                      } else {
                        data = (
                          <Button
                            bgColor="green"
                            color='white'
                            fontSize={{ sm: 'xs', md: 'sm' }}
                            fontWeight='500'
                            borderRadius='5px'
                            width="80%"
                            onClick={() => onClickAction({ type: 'Buy', product: row.original })}
                            px='15px'
                            py='5px'>
                            Beli ulang
                          </Button>
                        )
                      }
                    } else if (cell.column.Header === "ACTION" && cell.value.category !== "File PDF") {
                      if (row.values.status === 1) {
                        data = (
                          <Button
                            bgColor="green"
                            color='white'
                            width="80%"
                            fontSize={{ sm: 'xs', md: 'sm' }}
                            fontWeight='500'
                            borderRadius='5px'
                            onClick={() => onClickAction({ type: 'Web', product: row.original })}
                            px='25px'
                            py='5px'>
                            View
                          </Button>
                        )
                      } else {
                        data = (
                          <Button
                            bgColor="green"
                            color='white'
                            width="80%"
                            fontSize={{ sm: 'xs', md: 'sm' }}
                            fontWeight='500'
                            borderRadius='5px'
                            onClick={() => onClickAction({ type: 'Buy', product: row.original })}
                            px='15px'
                            py='5px'>
                            Beli ulang
                          </Button>
                        )
                      }
                    } else if (cell.column.Header === "TANGGAL BELI") {
                      data = (
                        <Text
                          color={"black"}
                          fontSize={{ sm: 'sm', md: 'md' }}
                          fontWeight='500'
                          overflow="hidden"
                          whiteSpace="nowrap"
                          maxWidth={200}
                          textOverflow="ellipsis">
                          {moment(cell.value).format('DD MMMM YYYY')}
                        </Text>
                      )
                    } else if (cell.column.Header === "STATUS") {
                      data = (
                        <Text
                          color={"black"}
                          fontSize={{ sm: 'sm', md: 'md' }}
                          fontWeight='500'
                          overflow="hidden"
                          whiteSpace="nowrap"
                          maxWidth={150}
                          textOverflow="ellipsis">
                          {cell.value === 1 ? "Succeeded" : "Failed"}
                        </Text>
                      )
                    } else {
                      data = (
                        <Text
                          color={"black"}
                          fontSize={{ sm: 'sm', md: 'md' }}
                          fontWeight='500'
                          overflow="hidden"
                          whiteSpace="nowrap"
                          maxWidth={150}
                          textOverflow="ellipsis">
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Flex direction="row" gap={2} alignItems="center" justifyContent="center" w={{ sm: "max-content", lg: '100%' }}>
          <Button
            borderWidth={1}
            // borderColor="#1877f2"
            bg={"white"}
            color='black'
            fontSize={{ sm: 'xs', md: 'sm' }}
            fontWeight='bold'
            borderRadius='5px'
            px='10px'
            py='5px'
            onClick={() => firstPage(type)}
            disabled={pagination.page === 1}
          >
            {'<<'}
          </Button>
          <Button
            borderWidth={1}
            // borderColor="#1877f2"
            bg={"white"}
            color='black'
            fontSize={{ sm: 'xs', md: 'sm' }}
            fontWeight='bold'
            borderRadius='5px'
            px='10px'
            py='5px'
            onClick={() => prevPage(type)}
            disabled={pagination.page === 1}
          >
            {'<'}
          </Button>
          <Flex borderWidth={1} bg={"white"} borderRadius='5px' px='10px' py='5px' gap={2}>
            <Text color="#000" fontSize={{ sm: 'sm', md: 'md' }}>Page </Text>
            <Input
              variant='search'
              fontSize={{ sm: 'xs', md: 'sm' }}
              bg={"white"}
              color={"black"}
              w={10}
              fontWeight='500'
              borderRadius={5}
              textAlign="center"
              placeholder={pagination.page}
              value={pagination.page}
              onChange={e => {
                const page = e.target.value
                goToPage(type, page)
              }}
            />
            <Text color="#000" fontSize="md"> / {pagination.max}</Text>
          </Flex>
          <Button
            borderWidth={1}
            // borderColor="#1877f2"
            bg={"white"}
            color='black'
            fontSize={{ sm: 'xs', md: 'sm' }}
            fontWeight='bold'
            borderRadius='5px'
            px='10px'
            py='5px'
            onClick={() => nextPage(type)}
            disabled={pagination.page === pagination.max}
          >
            {'>'}
          </Button>
          <Button
            borderWidth={1}
            // borderColor="#1877f2"
            bg={"white"}
            color='black'
            fontSize={{ sm: 'xs', md: 'sm' }}
            fontWeight='bold'
            borderRadius='5px'
            px='10px'
            py='5px'
            onClick={() => lastPage(type)}
            disabled={pagination.page === pagination.max}
          >
            {'>>'}
          </Button>
          <select
            style={{ padding: '5px 10px' }}
            value={pagination.limit}
            onChange={e => {
              limitPage(type, Number(e.target.value))
            }}
          >
            {[1, 5, 10, 20, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <Text fontSize={{ sm: 'xs', md: 'sm' }}>{pagination.limit > pagination.total ? pagination.total : pagination.limit} of {pagination.total}</Text>
        </Flex>
      </Flex>
    </>
  );
}

export default TablePurchased;
